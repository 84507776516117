.header-text {
  position: absolute;
  top: 0;
  right: 0;
  padding: inherit;
  z-index: 1;
  color: black;
}

.other-app {
  padding-left: 30px;
  padding-right: 30px;
}

.other-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.wrapper {
  padding-right: 30px;
}
