a,
u {
  text-decoration: none;
}

.app {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100%;
}

.footer-text {
  color: white;
  font-size: calc(6px + 2vmin);
}

.footer-text-privacy {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: inherit;
  z-index: 5;
}

.footer-text-terms {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: inherit;
  z-index: 1;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: #4ca51d;
}

.header-font {
  font-size: calc(10px + 2vmin);
  color: white;
}

.construction {
  padding-right: 50px;
  padding-left: 50px;
  width: calc(300px + 1vmin);
  height: calc(300px + 1vmin);
}
